/**
 * User: gschulz
 */
let ListTable = (function () {

    let _private = {
        order:                 {},
        filter:                {},
        filterDefaults:        {},
        page:                  1,
        limit:                 0,
        filterEventTs:         0,
        filterEventDelay:      500,
        afterRequestCallBacks: [],
        preLoadElement:        $(),
        url:                   null,
        options:               {},
        indexParamsDefault:    {},
        isPageLoaded:          true,
        browserHistory:        false,
        pageTitle:             null,
        filterForm:            undefined,
        table:                 undefined,
        tableHeader:           undefined,
        pagination:            undefined
    };

    _private.defaults = {
        limit: undefined
    };

    // this = this;

    _private.lastParams = {};

    /**
     *
     * @param {object} [options]
     * @returns {*}
     */
    this.init = (options) => {
        _private.initOptions(options);
        if ($(_private.table).length !== 1) {
            return;
        }
        _private.filter = getParams;
        this.initTableFiltering();
        this.initTableSorting();
        this.initPagination();
        this.initBrowserHistoryHandling();
        return this;
    };

    this.initBrowserHistoryHandling = () => {
        if (!_private.browserHistory) {
            return;
        }
        if (_.isNull(_private.pageTitle)) {
            _private.pageTitle = $('title').text();
        }
        let action = urlHelper.getState()['route']['action'];
        if (_private.isPageLoaded &&
            window.location.hash.substr(0, action.length + 2) === '#' + action + '?') {
            _private.isPageLoaded = false;
            this.request(undefined, true);
        }
        this.bindHistoryHandling();
        return this;
    };

    this.bindHistoryHandling = () => {
        $(window).unbind('statechange.listRequest').on('statechange.listRequest', () => {
            this.unbindHistoryHandling();
            this.request(undefined, true);
            $('title').text(_private.pageTitle);
            this.bindHistoryHandling();
        });
        return this;
    };

    this.unbindHistoryHandling = () => {
        $(window).unbind('statechange.listRequest');
        return this;
    };

    /**
     *
     * @param {jquery} pagination
     */
    _private.setLimit = (pagination) => {
        let limitSelect = $(pagination).find('select[name=limit]');
        _private.limit  = $(limitSelect).val();
        $(limitSelect).removeAttr('onchange');
        $(limitSelect).unbind('change.setLimitAndRequest').on('change.setLimitAndRequest',
            event => {
                _private.limit = $(event.target).val();
                this.request();
            }
        );
    };

    /**
     *
     * @param {object} options
     */
    _private.initOptions = (options) => {
        if (!_.isUndefined(options)) {
            $.each(options, (key, value) => {
                this.set(key, value);
            });
        }
        if (_.isUndefined(_private.filterForm)) {
            _private.filterForm = $('div.table-filter').find('form');
        }
        if (_.isUndefined(_private.table)) {
            _private.table = $('#content').find('table.list.content-table');
        }
        if (_.isUndefined(_private.tableHeader)) {
            _private.tableHeader = $('#content').find('table.list').find('thead');
        }
        if (_.isUndefined(_private.pagination)) {
            _private.pagination = $('div.paginator-controls');
        }
        if (!_private.preLoadElement.length) {
            //_private.preLoadElement = $(_private.table).parents('div.inner-content');
            _private.preLoadElement = $('body');
        }
        if (_.isUndefined(_private.url)) {
            _private.url = location.pathname;
        }
        if (_.isUndefined(_private.defaults.limit)) {
            _private.defaults.limit = _private.pagination.find('select[name=limit]').pVal();
        }
    };

    /**
     */
    this.initTableFiltering = () => {
        const eventHelper = new EventHelper();
        if (!_private.filterForm.length) {
            return this;
        }
        _private.filterForm
                .find('div.formelement.text')
                .find('input[type=text][name^=autocomplete]').each((i, field) => {
            field         = $(field);
            let endpoint  = field.data('endpoint');
            let property  = field.data('property');
            let valuePath = field.data('value-path') || 'object_id';
            let order     = field.data('order') || 'asc';
            if (!endpoint || !property) {
                return;
            }
            let name        = field.prop('name');
            let formElement = field.parent('div.formelement');
            let filterField = $('<input/>', {type: 'text', name: name.replace('autocomplete', 'filter')});
            formElement.append(filterField.hide());
            field.efbAutocomplete({
                minLength: 2,
                delay:     200,
                source(request, response) {
                    let params              = {filter: {}, order: {}};
                    params.filter[property] = {like: '%' + request.term + '%'};
                    params.order[property]  = order;
                    _private.request        = $.ajax({
                        url:      urlHelper.getSimpleUrl(endpoint, 'search'),
                        dataType: 'json',
                        data:     params,
                        success(data) {
                            response($.map(data, (object) => {
                                object.label = object[property];
                                return object;
                            }));
                        },
                        complete() {
                            field.removeClass('ui-autocomplete-loading');
                        },
                        error() {
                            field.removeClass('ui-autocomplete-loading');
                        }
                    });
                },
                select(event, ui) {
                    filterField.val(EfbHelper.getValueByKey(valuePath, ui.item, 0)).keyup();
                    filterField.data('object', ui.item);
                }
            });

        });

        _private.filterForm
                .find('div.formelement.text')
                .find('input[type=text]')
                .not('input[name^=autocomplete]')
                .unbind('keyup.filterTable').on('keyup.filterTable', event => {
                eventHelper.delayCall(() => {
                    let date               = new Date();
                    _private.filterEventTs = date.getTime();
                    this.filterTable(event.target);
                }, 'filterByTyping', [], 200);
            }
        );
        _private.filterForm
                .find('input[type=checkbox],input[type=radio]')
                .unbind('click.filterTable').on('click.filterTable', event => {
                let date               = new Date();
                _private.filterEventTs = date.getTime();
                this.filterTable(event.target);
            }
        );
        _private.filterForm.find('select').unbind('change.filterTable').on('change.filterTable', event => {
                this.filterTable(event.target);
            }
        );
        let datePickers = _private.filterForm.find('div.formelement.date-picker');
        if (datePickers.length) {
            let textField   = datePickers.find('input[type=text]');
            let inputField  = datePickers.find('input[type=hidden]');
            let eventHelper = new EventHelper();
            textField.unbind('keyup.filterTable');
            let formHelper = new EfbHelperForm();
            formHelper.initDatePicker(datePickers);
            inputField.unbind('change.filterTable').on('change.filterTable', event => {
                eventHelper.delayCall(
                    event => {
                        this.filterTable(event.target);
                    },
                    'filterTable', [event]);
            });
            textField.unbind('focus.selectAll').on('focus.selectAll', event => {
                setTimeout(() => {
                    $(event.target).select();
                }, 100);
            });
            textField.unbind('blur.selectAll').on('blur.selectAll', event => {
                let value = $(event.target).val();
                if (value === '') {
                    $(event.target).next('input[type=hidden]').val('').trigger('change');
                }
            });
        }
        $('div.table-filter').find('select').hiddenChosen({search_contains: true});
        return this;
    };

    /**
     *
     * @param {jquery} [element]
     */
    this.filterTable = (element) => {
        let form = $(element).parents('form:first');
        let data = form.serializeObject();
        // if (_private.filterEventTs + _private.filterEventDelay > date.getTime()) {
        //     if (compare) {
        //         return;
        //     }
        //     setTimeout(() => {
        //         this.filterTable(element, jQuery.extend(true, {}, data));
        //     }, _private.filterEventDelay);
        //     return;
        // }
        _private.filter = {};
        _private.page   = 1;
        if (!_.isUndefined(data.filter)) {
            $.each(data.filter, (key, value) => {
                if ($.trim(value) === '') {
                    delete data.filter[key];
                }
            });
        }
        let defaultFilters = $.extend(true, {}, _private.filterDefaults);
        _private.filter    = $.extend(true, defaultFilters, data);
        let requestParams  = _private.getRequestParams();
        if (JSON.stringify(requestParams) === JSON.stringify(_private.lastParams)) {
            return;
        }
        this.request();
    };

    this.initTableSorting = () => {
        if (!_private.tableHeader.length) {
            return this;
        }
        let sortElements = _private.tableHeader.find('th a.sort');
        sortElements.unbind('click.sortTable').on('click.sortTable',
            event => {
                _private.order       = {};
                let directionMap     = {
                    'asc':  'desc',
                    'desc': 'asc'
                };
                let nextDirection    = 'asc';
                let currentDirection = $(event.target).attr('direction');
                if (!_.isUndefined(directionMap[currentDirection])) {
                    nextDirection = directionMap[currentDirection];
                }
                let fieldName = $(event.target).attr('fieldname');
                sortElements.attr('direction', '');
                sortElements.removeClass('asc');
                sortElements.removeClass('desc');
                $(event.target).attr('direction', nextDirection);
                $(event.target).addClass(nextDirection);
                _private.order[fieldName] = nextDirection;
                this.request();

            }
        );
        return this;
    };

    /**
     *
     */
    this.initPagination = () => {
        if (_.isUndefined(_private.pagination)) {
            return;
        }
        _private.setLimit(_private.pagination);
        _private.pagination.find('a[pagenumber]').unbind('click.paginate').on('click.paginate',
            event => {
                event.preventDefault();
                _private.page = $(event.currentTarget).attr('pagenumber');
                this.request();
            }
        );
        return this;
    };

    /**
     *
     * @param {boolean|undefined} [preLoad]
     * @param {boolean|undefined} [fromHistoryState]
     */
    this.request = (preLoad, fromHistoryState) => {
        let url             = _private.url;
        let data            = _private.getRequestParams();
        _private.lastParams = $.extend(true, {}, data);
        if (_private.browserHistory) {
            // noinspection JSCheckFunctionSignatures
            url  = _private.handleBrowserHistory(data, url, fromHistoryState);
            data = {};
        }
        if (_private.request && _private.request.abort && _.isUndefined(_private.request.status)) {
            console.log(_private.request.status);
            _private.request.abort();
        }
        const self       = this;
        _private.request = $.ajax({
            url:         url,
            type:        'get',
            data:        data,
            dataType:    'json',
            ajaxElement: _private.preLoadElement,
            success(response) {
                if (response['error_key'] === 'maintenance_mode' && urlHelper.getState().route.controller !== 'maintenance') {
                    location.href = urlHelper.getSimpleUrl('maintenance', 'index');
                }
                if (!_.isUndefined(response['tableContent'])) {
                    $(_private.table).find('tbody').replaceWith(response['tableContent']);
                }
                if (!_.isUndefined(response['tableHeader']) && $(_private.table).find('thead').find('th').length === 0) {
                    let tableHeader = $(response['tableHeader']);
                    $(_private.table).find('thead').replaceWith(tableHeader);
                    _private.tableHeader = tableHeader;
                }
                if (!_.isUndefined(response['pagination'])) {
                    let newPagination = $(response['pagination']);
                    $('div.paginator-controls').replaceWith(newPagination);
                    _private.pagination = newPagination;
                }
                self.initPagination();
                $.each(_private.afterRequestCallBacks, (i, func) => {
                    if (!_.isFunction(func)) {
                        return;
                    }
                    func.apply(this, [response, _private.table]);
                });
                if (!_private.afterRequestCallBacks.length) {
                    self.initTableFiltering();
                    self.initTableSorting();
                    self.initPagination();
                    self.initBrowserHistoryHandling();
                }
            }
        });
        return this;
    };

    /**
     *
     * @param {object} data
     * @param {string} url
     * @param {boolean} fromHistoryState
     * @returns {_private.initState.hash|*|string|e.document.location.hash|hash|.contentWindow.document.location.hash}
     */
    _private.handleBrowserHistory = (data, url, fromHistoryState) => {
        History.options.html4Mode = true;
        let state                 = History.getState();
        if (fromHistoryState) {
            let urlParams = {};
            parse_str(state.url.split('?')[1], urlParams);
            _private.fillUrlParamsToDom(urlParams);
            $('title').text(_private.pageTitle);
        } else {
            this.unbindHistoryHandling();
            History.pushState(null, _private.pageTitle, url + '?' + jQuery.param(data));
            state = History.getState();
            $('title').text(_private.pageTitle);
            this.bindHistoryHandling();
        }
        return state.hash;
    };

    /**
     *
     * @param {object} urlParams
     */
    _private.fillUrlParamsToDom = (urlParams) => {
        let defaults = {
            limit:  _private.defaults.limit,
            order:  {},
            filter: {}
        };
        let params   = {};
        $.extend(true, params, defaults, _private.indexParamsDefault, urlParams);
        $('select[name=limit]').val(params.limit);
        $('div.table-header').find('a.sort').attr('direction', '').removeClass('asc').removeClass('desc');
        $.each(params.order, (field, direction) => {
            let sortLink = $('div.table-header').find('a.sort[fieldname=' + field + ']');
            sortLink.attr('direction', direction).addClass(direction);
        });
        let form = $('div.table-filter').find('form');
        form.find('input[type=text],select').each((i, el) => {
            $(el).val('');
        });
        form.populate(params);
        this.openFilterPanelIfNotEmpty();
        _private.filter = params;
        _private.limit  = params.limit;
    };

    this.openFilterPanelIfNotEmpty = () => {
        let filerPanel  = $('div.table-filter');
        let isEmptyForm = true;
        filerPanel.find('input,select').each((i, input) => {
            input            = $(input);
            let value        = '';
            let defaultValue = input.data('default_value');
            switch (true) {
                case input.is('input[type=text]'):
                case input.is('select'):
                    value = input.val();
                    break;
                case input.is('input[type=checkbox]'):
                    value = input.is(':checked') ? '1' : '';
                    break;
                default :
                    break;
            }
            if ((_.isUndefined(defaultValue) && value !== '') ||
                (!_.isUndefined(defaultValue)) && defaultValue !== value) {
                isEmptyForm = false;
                return false;
            }
        });
        if (!isEmptyForm) {
            filerPanel.show();
        }
        return this;
    };

    _private.getRequestParams = () => {
        let data   = _private.filter;
        data.order = _private.order;
        data.page  = _private.page;
        data.limit = this.getLimit();
        return data;
    }

    this.getLimit = () => {
        if (_.isUndefined(_private.limit) || _.isNaN(_private.limit)) {
            _private.limit = _private.defaults.limit;
        }
        return _private.limit;
    };

    this.set = (key, val) => {
        _private[key] = val;
        return this;
    };

    this.get = (key) => {
        return _private[key];
    }


});